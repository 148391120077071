import React, { Component } from 'react'

// Components
import SwipeableModal from "../../SwipeableModal";
import ErrorModal from "../../ErrorModal";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import Header from "../../Header";
import PartnersFooter from "../../PartnersFooter";

//lib
import { withRouter } from "react-router-dom";
import parse from 'html-react-parser';
import QRCode from "qrcode.react";
import { isAndroid, isMobile } from 'react-device-detect';

//images
import ontarioIcon from "../../../assets/icon-ontario.svg";
import logoVerifiedMe from "../../../assets/logo-verified-me.svg";
import checkIcon from "../../../assets/icon-check.png";

//Services
import i18n from '../../../i18n';
import IDPConnectService from "../../Services/IDPConnectService";

// Helpers
import { Consumer } from "../../../helpers/context";

class SPLoginContainer extends Component {
    state = {
        isModalOpen: false,
        device: this.props.xDevice,
        idpRedirectUrl: null,
        hasError: false,
        errorTitle: "",
        errorMessage: "",
        isLoginFailed: this.props.isLoginFailed,
        isSendingReq: false,
        isModalSendingReq: false,
    }

    componentDidMount = () => {
        const { isLoginFailed } = this.state;

        let errTitle = "";
        let errMessage = "";
        let hasError = false;

        if (isLoginFailed) {
            hasError = true
            errTitle = i18n.t("login__logging_error_title");
            errMessage = i18n.t("login__logging_error_desc");
        }

        this.setState({
            errorTitle: errTitle,
            errorMessage: errMessage,
            hasError,
        });
        
    }
  
    componentWillUnmount = () => {
        this.setState({
            isSendingReq: false,
            isModalSendingReq: false
        })
    }

    handleToggleModal = () => {
        const { isModalOpen } = this.state;

        this.setState({ isModalOpen: !isModalOpen })
    }

    handleGetStartedAction = (isWebSendingReq, isModalSendingReq) => {

        const { idp } = this.props;

        this.setState({ isSendingReq: isWebSendingReq, isModalSendingReq })

        return IDPConnectService.LoginToIdp(idp, "consent", "CONSENT_QR").then(
            redirectUrl => this.setState({ idpRedirectUrl: redirectUrl })).catch(e => {
                this.setState({
                    hasError: true,
                    isModalOpen: false,
                    errorTitle: i18n.t("signin_failure_title"),
                    errorMessage: i18n.t("signin_failure_desc"),
                    isSendingReq: false,
                    isModalSendingReq: false
                })
            });
    }

    handleToggleErrorModal = () => {
        // Clearing browser state 
        window.history.replaceState("state", null);
        window.localStorage.clear();
        this.setState({ isLoginFailed: false, hasError: false })
    }

    handlePartnerAppClick = () => {
        this.props.history.push(window.GLOBAL_PATH+"signUpPartnerApp");
    }

    handleNavRedirect = (navType) => {
        let tempRedirectUrl;

        if (navType === "NIAGARA_HEALTH") {
            if (isAndroid) {
                tempRedirectUrl = window.config.nh_play_store_url;
            } else {
                tempRedirectUrl = window.config.nh_app_store_url;
            }
        }

        if (navType === "SOUTHLAKE") {
            if (isAndroid) {
                tempRedirectUrl = window.config.sl_play_store_url;
            } else {
                tempRedirectUrl = window.config.sl_app_store_url;
            }
        }

        if (navType === "NORTH_YORK") {
            if (isAndroid) {
                tempRedirectUrl = window.config.ny_play_store_url;
            } else {
                tempRedirectUrl = window.config.ny_app_store_url;
	        }
	    }

	    if (navType === "ST_JOSEPH") {
            if (isAndroid) {
                tempRedirectUrl = window.config.sj_play_store_url;
            } else {
                tempRedirectUrl = window.config.sj_app_store_url;
            }
        }

        window.open(tempRedirectUrl, '_blank');
    }

    render() {
        const {
            isModalOpen,
            device,
            idpRedirectUrl,
            hasError,
            errorTitle,
            errorMessage,
            isLoginFailed,
            isSendingReq,
            isModalSendingReq,
            idp
        } = this.state;

        const {
            handleToggleNetworkModal,
            isInternetDisconnected,
            user_code,
            verification_uri_complete,
            sp_name,
            handleCreateAccountClick
        } = this.props;

	var verification_uri_complete_updated = verification_uri_complete && verification_uri_complete.split('://')[1];

        if (idpRedirectUrl !== null) {
            window.top.location.href = idpRedirectUrl;
        }

        const modalContent = [{
            panelElements: [
                i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
                i18n.t("sign_in_modal_flow__panel_1_title"),
                i18n.t("sign_in_modal_flow__panel_1_desc")
            ]
        }, {
            panelElements: [
                i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
                i18n.t("sign_in_modal_flow__panel_2_title"),
                i18n.t("sign_in_modal_flow__panel_2_desc", { logoImage: logoVerifiedMe, listIcon: checkIcon }),
                i18n.t("sign_in_modal_flow__panel_2_learn_more_link", { link: "/" })
            ]
        }, {
            panelElements: [
                i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
                i18n.t("sign_in_modal_flow__panel_3_title"),
                i18n.t("sign_in_modal_flow__panel_3_desc", { listIcon: checkIcon }),
            ],
        }];

        return (
            <React.Fragment>
                <Consumer>
                    {(value) => (
                        <div 
                            className="spLogin"
                            onClick={value.handleCloseMenu} 
                        >
                            <div className="spLogin-container">
                                <Header 
                                    isMenuOpen={value.isMenuOpen} 
                                    handleToggleMenu={value.handleToggleMenu}
                                    isDrawerOpen={value.isDrawerOpen}
                                    handleToggleDrawer={value.handleToggleDrawer}
                                />
                                
                                <div className="allow-sp-container">
                                    <div className="allow-sp-wrapper">
                                        <h2 
                                            className="allow-sp-title" 
                                            dangerouslySetInnerHTML={{
                                                __html: i18n.t('spLogin_allow_title', {
                                                sp_name,
                                                })
                                            }}
                                        />
                                        <hr className="allow-divider backgroundPrimaryColor" />
                                        <div className="allow-sp-text">{i18n.t("spLogin_allow_text")}</div>

                                        {
                                            !isMobile
                                            ?
                                            <div className="allow-options">
                                                <div className="allow-opt1-wrapper">
                                                    <div className="opt1-wrapper">
                                                        <p className="allow-opt1-title textPrimaryColor">
                                                            {i18n.t("spLogin_option1")}
                                                        </p>
                                                        <p className="allow-opt1-text">
                                                            <strong>{i18n.t("spLogin_allow_opt1_text")}</strong>
                                                        </p>
                                                        <div className="opt1-steps">
                                                            <ol>
                                                                <li>{parse(i18n.t("spLogin_opt1_steps_1"))}</li>
                                                                <li>{parse(i18n.t("spLogin_opt1_steps_2"))}</li>
                                                                <li>{parse(i18n.t("spLogin_opt1_steps_3"))}</li>
                                                            </ol>
                                                        </div>

                                                        {
                                                            verification_uri_complete
                                                            &&
                                                            <div className="cant-scan-text">
                                                                <p>{i18n.t("spLogin_cant_scan_qrCode1")}</p>
                                                                <div
                                                                    
                                                                    dangerouslySetInnerHTML = {
                                                                    {
                                                                        __html: i18n.t('spLogin_cant_scan_qrCode2', {
                                                                        user_code,
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                    {
                                                        verification_uri_complete
                                                        &&
                                                        <div className="qr-code-img">
                                                            <QRCode value={verification_uri_complete_updated} />
                                                        </div>
                                                    }
                                                    
                                                </div>
                                                <div className="options-divider" />
                                                <div className="allow-opt2-wrapper">
                                                    <div className="allow-opt2-title textPrimaryColor">
                                                        {i18n.t("spLogin_option2")}
                                                    </div>
                                                    <div className="allow-opt2-text">
                                                        {parse(i18n.t("spLogin_allow_opt2_text"))}
                                                    </div>

                                                    <ButtonWithSpinner
                                                        isSendingReq={isSendingReq}
                                                        isInternetDisconnected={isInternetDisconnected}
                                                        aria-label={i18n.t("spLogin_signIn_button")}
                                                        handleToggleNetworkModal={handleToggleNetworkModal}
                                                        buttonText={i18n.t("spLogin_signIn_button")}
                                                        handleGetStartedAction={() => this.handleGetStartedAction(true)}
                                                        className = "allow-signin-online-button backgroundPrimaryColor button--spinner-bg button--spinner-bg--white"
                                                    />
                                                </div>
                                            </div>                                       
                                            :
                                            <div className="options-phone">
                                                <div className="options-phone-wrapper">
                                                    <div className="options-phone-mask">
                                                        <h3 className="options-phone-text">
                                                            {i18n.t("spLogin_options_phone_txt")}
                                                        </h3>
                                                        <div className="options-phone-buttons-wrapper">
                                                            <button
                                                                className="options-niag-button"
                                                                onClick={() => this.handleNavRedirect("NIAGARA_HEALTH")}
                                                                aria-label={i18n.t("spLogin_options_niag_button_txt")} aria-describedby="new-window"
                                                            >
                                                                {i18n.t("spLogin_options_niag_button_txt")}
                                                                <span className='sr-only'>{i18n.t("new_tab_msg_for_sr")}</span>
                                                            </button>
                                                            <button
                                                                className="options-northYork-button"
                                                                onClick={() => this.handleNavRedirect("NORTH_YORK")}
                                                                aria-label={i18n.t("spLogin_options_ny_button_txt")} aria-describedby="new-window"       
                                                            >
                                                                {i18n.t("spLogin_options_ny_button_txt")}
                                                                <span className='sr-only'>{i18n.t("new_tab_msg_for_sr")}</span>
                                                            </button>
                                                            <button
                                                                className="options-southLake-button"
                                                                onClick={() => this.handleNavRedirect("SOUTHLAKE")}
                                                                aria-label={i18n.t("spLogin_options_sl_button_txt")} aria-describedby="new-window"       
                                                            >
                                                                {i18n.t("spLogin_options_sl_button_txt")}
                                                                <span className='sr-only'>{i18n.t("new_tab_msg_for_sr")}</span>
                                                            </button>
                                                            <button
                                                                className="options-stJoseph-button"
                                                                onClick={() => this.handleNavRedirect("ST_JOSEPH")}
                                                                aria-label={i18n.t("spLogin_options_sj_button_txt")} aria-describedby="new-window"       
                                                            >
                                                                {i18n.t("spLogin_options_sj_button_txt")}
                                                                <span className='sr-only'>{i18n.t("new_tab_msg_for_sr")}</span>
                                                            </button>
                                                        </div>
                                                    </div>


                                                    <div className="allow-opt2-text">
                                                        {parse(i18n.t("spLogin_allow_opt2_text"))}
                                                    </div>                                           

                                                    <ButtonWithSpinner
                                                        isSendingReq={isSendingReq}
                                                        isInternetDisconnected={isInternetDisconnected}
                                                        aria-label={i18n.t("spLogin_signIn_button")}
                                                        handleToggleNetworkModal={handleToggleNetworkModal}
                                                        buttonText={i18n.t("spLogin_signIn_button")}
                                                        handleGetStartedAction={() => this.handleGetStartedAction(true)}
                                                        className="allow-signin-online-button  backgroundPrimaryColor button--spinner-bg button--spinner-bg--white"
                                                    />
                                                </div>
                                            </div>
                                        }                   
                                    </div>
                                </div>

                                <div className="sp-setup-trusted-container">
                                    <div className="sp_setup_trusted_wrapper">
                                        <div className="sp-setup-text-wrapper">
                                            <h3 className="sp_setup_heading">
                                                {i18n.t("spLogin_setup_heading")}
                                            </h3>
                                            <hr className="sp-setup-divider backgroundPrimaryColor" />
                                            <p className="sp-setup_access_text">
                                                {i18n.t("spLogin_setup_access_text")}
                                            </p>
                                        </div>

                                        <div className="sp-set-account-mask">
                                            <div className="sp-set-account-mask_container">
                                                <h3 className="sp-mask-getstarted-text">
                                                    {i18n.t("spLogin_getstarted_text")}
                                                </h3>
                                                <div className="sp-mask-buttons-wrapper">
                                                    <button
                                                        className="sp-mask-use-app-button backgroundPrimaryColor"
                                                        aria-label={i18n.t("spLogin_use_app_btn_txt")}
                                                        onClick={this.handlePartnerAppClick}

                                                    >
                                                        {i18n.t("spLogin_use_app_btn_txt")}
                                                    </button>
                                                    <button
                                                        className="sp-mask-setup-accnt-button borderPrimaryColor textPrimaryColor "
                                                        aria-label={i18n.t("spLogin_setup_accnt_btn_txt")}
                                                        onClick={handleCreateAccountClick}
                                                    >
                                                        {i18n.t("spLogin_setup_accnt_btn_txt")}
                                                    </button>
                                                </div>       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Partners Sections */}
                                
                                <PartnersFooter />
                            </div>

                            {
                                isModalOpen
                                &&
                                <SwipeableModal
                                    isOpen={isModalOpen}
                                    showCloseIcon={true}
                                    showPagination={false}
                                    handleToggleModal={this.handleToggleModal}
                                    modalContent={modalContent}
                                    handleGetStartedAction={() => this.handleGetStartedAction(false, true)}
                                    device={device}
                                    isSendingReq={isModalSendingReq}
                                    isInternetDisconnected={isInternetDisconnected}
                                    handleToggleNetworkModal={handleToggleNetworkModal}
                                    idp={idp}
                                />
                            }

                            {
                                (hasError || isLoginFailed)
                                &&
                                <ErrorModal
                                    isOpen={hasError || isLoginFailed}
                                    errorTitle={errorTitle}
                                    errorMessage={errorMessage}
                                    handleToggleModal={this.handleToggleErrorModal}
                                />
                            }
                        </div>
                    )}
                </Consumer>
            </React.Fragment>
        )
    }
}

export default withRouter(SPLoginContainer);
