import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import SignInContainer from "../container/SignInContainer";
import CreateAccountContainer from "../../CreateAccountPage/container/CreateAccountContainer";

// Services
import i18n from '../../../i18n';

class SignInPage extends Component {
  state = {
    isCreateAccount: false
  }

  handleCreateAccountClick = () => {
    this.setState({isCreateAccount: !this.state.isCreateAccount})
    window.scrollTo(0, 0);
  }

  render() {
    const { isInternetDisconnected, handleToggleNetworkModal, history } = this.props;
    const { isCreateAccount } = this.state;
    const platform = window.PLATFORM;
    const mobileScheme = window.MOBILE_SCHEME;
    const idp = window.IDP_OPTIONS && window.IDP_OPTIONS.length && window.IDP_OPTIONS[0].idpAuthenticatorId;
    return (
      <div className="sign-in-page">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("sign_in_page_title")}`} />
        {
          !isCreateAccount ? (
            <SignInContainer 
              isInternetDisconnected={isInternetDisconnected}   
              handleToggleNetworkModal={handleToggleNetworkModal}
              isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed}
              xDevice={platform}
              idp={idp}
              mobileScheme={mobileScheme}
              history={history}
              handleCreateAccountClick={this.handleCreateAccountClick}
            />
          ) : (
            <CreateAccountContainer 
              isInternetDisconnected={isInternetDisconnected}   
              handleToggleNetworkModal={handleToggleNetworkModal}
              isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed}
              xDevice={platform}
              idp={idp}
              mobileScheme={mobileScheme}
              history={history}
              handleCreateAccountClick={this.handleCreateAccountClick}
            />
          )
        }
      </div>
    );
  }
}

export default SignInPage;
