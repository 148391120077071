import React, { Component } from "react";

// Components
import LoginPageModalTemplate from "./ModalTemplates/LoginPageModalTemplate";

// Lib
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';

//Services
import i18n from '../i18n';

class SwipeableModal extends Component {
  render() {
    const {
      isOpen,
      handleToggleModal,
      modalContent,
      showCloseIcon,
      handleGetStartedAction,
      showPagination,
      device,
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal,
      idp,
      mobileScheme,
      handleScrollToTop
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={true}
        overlayClassName={(device === "web") ? "modal-overlay signInModal web-modal-overlay" : "modal-overlay signInModal"}
        contentLabel={i18n.t("signin_web_trusted_setUp_btn")}
      >
        <React.Fragment> 
          <div id="modal-content">
            {
              (showCloseIcon && (device !== "android"))
              &&
              <div className="modal-exit-wrapper">
                <button aria-label="close" className="close-icon" onClick={() => handleToggleModal("")} >
                  <CloseIcon />
                </button>
              </div>
            }

            <LoginPageModalTemplate 
              modalContent={modalContent}
              handleGetStartedAction={handleGetStartedAction}
              handleToggleModal={handleToggleModal}
              showPagination={showPagination}
              device={device}
              isSendingReq={isSendingReq}
              isInternetDisconnected={isInternetDisconnected}
              handleToggleNetworkModal={handleToggleNetworkModal}
              idp={idp}
              mobileScheme={mobileScheme}
              handleScrollToTop={handleScrollToTop}
            />
          </div>
        </React.Fragment>
      </Modal>  
    );
  }
}

export default SwipeableModal;