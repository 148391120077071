import React, { Component } from 'react';

//Lib
import { Link } from 'react-router-dom';

//Services
import i18n from '../i18n';

class HeaderMenu extends Component {
  render() {

    return (
      <div className="menu-container">
        <ul>
          <li><Link target="_top" className="textPrimaryColor" to={window.GLOBAL_PATH+"support"}>{i18n.t("headerMenu_support")}</Link></li>
          <li><Link target="_top" className="textPrimaryColor" to={window.GLOBAL_PATH+"terms-of-use"}>{i18n.t("headerMenu_terms_of_use")}</Link></li>
          <li><Link target="_top" className="textPrimaryColor" to={window.GLOBAL_PATH+"privacy-policy"}>{i18n.t("headerMenu_privacy_policy")}</Link></li>
          <li><Link target="_top" className="textPrimaryColor" to={window.GLOBAL_PATH+"user-consent-for-ministry-of-health"}>{i18n.t("headerMenu_user_consent_MOH")}</Link></li>
          {/* <li><Link target="_top" className="textPrimaryColor" to={window.GLOBAL_PATH+"partners-and-participants"}>{i18n.t("headerMenu_partner_partnerships")}</Link></li> */}
        </ul>
      </div>
    )
  }
}

export default HeaderMenu;