import React, { Component } from 'react';

// Components
import BottomLinks from "../../BottomLinks";
import LogosWrapper from "../../common/LogosWrapper"

//lib
import parse from 'html-react-parser';

// Images
import ontarioIcon from "../../../assets/logo-ontario.svg";

// Services
import i18n from '../../../i18n';

class MobSignInContainer extends Component {

  render() {
    const {
      device,
      mobileScheme,
      idp,
      handleCreateAccountClick
    } = this.props;
    
    return (
      <div className="signin mobile-signin">
        <div className={(device === "android") ? "signin__SigninContainer android_margin" : (device === "ios") ? "signin__SigninContainer ios_margin" : "signin__SigninContainer def_margin"}>
          <header
            className={(device === "android") ? "imgContainer android_imgPadding" : (device === "ios") ?
              "imgContainer ios_imgPadding" : "imgContainer def_imgPadding"}>
            <img src={ontarioIcon} className="center" alt="" role="presentation" />
              
            <h1 className="titleText">{i18n.t("signIn_title")}</h1>
          </header>

          <section className="signinContentsWrapper">
            <div className="gatewayTextWrapper">
              <div className="gatewaySubText" >
                {parse(i18n.t("signIn_openText", { name: device + "_text" }))}
              </div>

            </div>

            <div className="buttonsWrapper">
              {/* Hidden element */}
              
              <span className='sr-only' id="new-window">{i18n.t("new_tab_msg_for_sr")}</span>
              <button
                aria-label={i18n.t("signIn_Setup_link_text")}
                className={(device === "android") ? "button android_margin modal-flow-btn backgroundPrimaryColor" : (device === "ios") ? "button ios_margin modal-flow-btn backgroundPrimaryColor" : "button def_margin modal-flow-btn backgroundPrimaryColor"}
                onClick={handleCreateAccountClick}
              >
                {i18n.t("signIn_Setup_link_text")}
              </button>

              <a 
                href={`${mobileScheme}login?idp=${idp}`}
                className = {
                  (device === "android") ? "button android_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg" : (device === "ios") ? "button ios_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg" : "button def_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg"
                }
              >
                {i18n.t("signIn_existing_link_text")}
              </a>
              
              {
                device === "android"
                ?
                <a className="textPrimaryColor learnMore" href={window.GLOBAL_PATH} target="_blank" rel="noopener noreferrer">{i18n.t("signIn_learnMore_link_text")} <span className='sr-only'>{i18n.t("new_tab_msg_for_sr")}</span></a>
                :
                <a className="textPrimaryColor learnMore" href={window.GLOBAL_PATH} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">{i18n.t("signIn_learnMore_link_text")}</a>
              }
            </div>
          </section>
        </div>

        <footer className="footer-div">
          <div className="fundedDesc">{i18n.t("signIn_fundedBy_text")}</div>

          <LogosWrapper />
          
          <BottomLinks />
        </footer>
      </div>
    )
  }
}

export default MobSignInContainer;
