import React, { Component } from "react";


class Spinner extends Component {
  handleCreatDivs = () => {
    let divArray = [];

    for (let i = 0; i <= 12; i++) {
      divArray.push(<div key={i} className="backgroundPrimaryColor" />)
    }

    return divArray;
  }

  render() {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-container">
          {this.handleCreatDivs()}
        </div>
      </div>
    );
  }
}

export default Spinner;
