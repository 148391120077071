import React from "react";

import NetworkDetector from "../NetworkDetector";

const WithoutSidebarLayout = ({ children, handleReload, isInternetDisconnected, handleToggleNetworkModal }) => {
  return (
    <div className="withoutSidebar" id="ontario-trusted-account">
      {
        React.Children.map(children, child =>
        React.cloneElement(child, {
        handleReload: handleReload,
        isInternetDisconnected: isInternetDisconnected,
        handleToggleNetworkModal: handleToggleNetworkModal
        }))
      }
    </div>
  );
};

export default NetworkDetector(WithoutSidebarLayout);