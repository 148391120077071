import React, { Component } from 'react'

// Lib
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AnalyticsService from "analytics-web";
import parse, { domToReact } from 'html-react-parser';


//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";

export default class FaqContainer extends Component {

  constructor(props) {
    super(props);

    let tempSelectedFaqIDs = [];
    if (props.incomingFaqID) {
      tempSelectedFaqIDs = [props.incomingFaqID]
    }
    this.state = {
      selectedFaqIDs: tempSelectedFaqIDs,
      selectedContentTitleIDs: []
    };
  }

  componentDidMount = () => {
    const { incomingFaqID } = this.props;

    this.handleAnalyticsTrack("onLoad_event");

    if (incomingFaqID) {
      //Scroll to specific section if url has faqid
      document.getElementById(incomingFaqID).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'IAA login themes',
      page: "FAQ page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  handleRenderContent = () => {

    let faqList = i18n.t("faq__header__content", {returnObjects: true});

    return faqList.map((item, i) => (
      <div className="section-wrap" key={i}>
        {item.title && <h3>{item.title}</h3>}

        {
          item.content_list && item.content_list.map(item3 => (
          this.handleSectionContent(item3)
          ))
        }
        
        {
          item.info_box && <div className='info-box'>{parse(item.info_box)}</div>
        }
      </div>

    ))
  }

  handleSectionContent = (section) => {
    const {
      selectedFaqIDs,
    } = this.state;
   
    return (
      <div className="content-item" key={section.faq_id}>
        <button 
          onClick={() => this.handleFaqClick(section)}
          id={section.faq_id}
        >
          <span className={'title'}>{section.faq}</span>

          {
            (selectedFaqIDs.includes(section.faq_id))
            ?
            <ArrowDropUpIcon className="caretStyle textPrimaryColor" />
            :
            <ArrowDropDownIcon className="caretStyle textPrimaryColor" />
          }
        </button>
        
        {
          selectedFaqIDs.includes(section.faq_id)
          &&
          section.content_list.map(item2 => (
            this.renderCategoryFaqQuestions(item2)
          ))
        }
      </div>   
    )
  }

  renderCategoryFaqQuestions = (faqQuestion) => {
    const { selectedContentTitleIDs } = this.state;

    // Add the onclick method to the scroll btn
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class ) {
          if (domNode.attribs.class.includes('scroll-btns')) {
            return <button className={domNode.attribs.class} onClick={() => this.handleScroll(domNode.attribs.faq_id, domNode.attribs.question_id)}>{domToReact(domNode.children, options)}</button>;
          }

          if (domNode.attribs.class.includes('privacy-policy') || domNode.attribs.class.includes('support')) {
            return <a className={domNode.attribs.class} target={domNode.attribs.target} rel={domNode.attribs.rel} href={`${window.GLOBAL_PATH}${domNode.attribs.class.includes('privacy-policy') ? 'privacy-policy' : 'support'}`}>{domToReact(domNode.children, options)}</a>;
          }

          if ((domNode.attribs.class.includes('tel') || domNode.attribs.class.includes('mail') || domNode.attribs.class.includes('link'))) {
            return <a className={domNode.attribs.class} href={domNode.attribs.href} rel={domNode.attribs.rel} target={domNode.attribs.target} onClick={() => this.handleAnalyticsTrack("onClick_event", domNode.attribs.click_details)}>{domToReact(domNode.children, options)}</a>;
          }
        }
      }
    };

    return (
      <div 
        className="category-faq-item content-item borderPrimaryColor"
        key={faqQuestion.title_id} 
      >
        <button 
          onClick={() => this.handleContentTitleClick(faqQuestion)}
          id={faqQuestion.title_id}
        >
          <span className="textPrimaryColor">{parse(faqQuestion.title)}</span>

          {
            (selectedContentTitleIDs.includes(faqQuestion.title_id))
            ?
            <ArrowDropUpIcon className="caretStyle textPrimaryColor" />
            :
            <ArrowDropDownIcon className="caretStyle textPrimaryColor" />
          }
        </button>

        {
          (selectedContentTitleIDs.includes(faqQuestion.title_id))
          &&
          <div className="description">
            {
              parse(faqQuestion.description, options)
            }
          </div>
        }
      </div>
    )
  }

  handleFaqClick = (faq) => {
    const { selectedFaqIDs } = this.state;

    let tempSelectedTitleIDs = selectedFaqIDs;
    
    if (tempSelectedTitleIDs.includes(faq.faq_id)) {
      tempSelectedTitleIDs = tempSelectedTitleIDs.filter(item => item !== faq.faq_id);
    } else {
      tempSelectedTitleIDs = [...tempSelectedTitleIDs, faq.faq_id]
    }

    this.setState({
      selectedFaqIDs: tempSelectedTitleIDs,
    });
  }

  handleContentTitleClick = (faqQuestion) => {
    const {
      selectedContentTitleIDs
    } = this.state;

    let tempSelectedContentTitleIDs = selectedContentTitleIDs;

    if (tempSelectedContentTitleIDs.includes(faqQuestion.title_id)) {
      tempSelectedContentTitleIDs = tempSelectedContentTitleIDs.filter(item => item !== faqQuestion.title_id);
    } else {
      //Analytics only when open the question
      this.handleAnalyticsTrack("onClick_event", `Clicked question - ${faqQuestion.title}`);

      tempSelectedContentTitleIDs = [...tempSelectedContentTitleIDs, faqQuestion.title_id]
    }

    this.setState({
      selectedContentTitleIDs: tempSelectedContentTitleIDs,
    });
  }

  handleScroll = (faq_id, question_id) => {
    const { selectedFaqIDs, selectedContentTitleIDs } = this.state;

    let tempSelectedFaqIDs = selectedFaqIDs;
    let tempSelectedContentTitleIDs = selectedContentTitleIDs;

    if (!tempSelectedFaqIDs.includes(faq_id)) {
      tempSelectedFaqIDs.push(faq_id)
    }

    if (!tempSelectedContentTitleIDs.includes(question_id)) {
      tempSelectedContentTitleIDs.push(question_id)
    }

    this.setState({
      selectedFaqIDs: tempSelectedFaqIDs,
      selectedContentTitleIDs: tempSelectedContentTitleIDs
    },  () => {
      document.getElementById(question_id).scrollIntoView();
    });
  }

  render() {
    return (
      <React.Fragment>
        <Consumer>
            {(value) => (
              <div 
                className="signup-partners-app-container"
                onClick={value.handleCloseMenu} 
              >
                <div className="privacy-policy-container">

                  <div className="content">
                    <section className="faq-section">
                      {
                        this.handleRenderContent()
                      }
                    </section>
                  </div>
                </div>
              </div>
          )}
        </Consumer>
      </React.Fragment>
    )
  }
}
