import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import PrivacyPolicyContainer from "../container/PrivacyPolicyContainer";

// Services
import i18n from '../../../i18n';

class PrivacyPolicy extends Component {

  render() {
    return (
      <div className="privacy-policy">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("privacy_policy__header_title")}`} />
        <PrivacyPolicyContainer />
      </div>
    );
  }
}

export default PrivacyPolicy;
