import React from "react";

import Modal from "react-modal";

import i18n from "../i18n";

class ErrorModal extends React.Component {
  handleReload = () => {
    window.location.reload();
  }

  render() {
    const { errorTitle, errorMessage, isOpen, handleToggleModal } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={true}
        overlayClassName="modal-overlay"
      >
        <div className="modal-wrapper error-modal">
          <h2 className="header">
            {errorTitle}
          </h2>
          <div className="modal-body">
            {errorMessage}
          </div>
          <div className="footer">
            <button onClick={handleToggleModal ? handleToggleModal : this.handleReload} className="okBtn  backgroundPrimaryColor">{i18n.t("ok")}</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ErrorModal;