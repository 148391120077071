import React, { Component } from "react";

// Lib
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import axios from 'axios';
import AnalyticsService from "analytics-web";
import qs from "query-string";

// Routes
import { PublicRoute } from "./PublicRoute";

// Components
import SignInPage from "./components/SignInPage/layout/SignInPage";
import WithoutSidebarLayout from "./components/Layout/WithoutSidebarLayout";
import SPloginPage from "./components/SPloginPage/layout/SPloginPage";
import SignupPartnerAppPage from "./components/SignupPartnerAppPage/layout/SignupPartnerAppPage";
import PrivacyPolicy from "./components/PrivacyPolicy/layout/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse/layout/TermsOfUse";
import Support from "./components/Support/layout/Support";
import LearnMoreONTA from "./components/LearnMoreONTA/layout/LearnMoreONTA";
// import PartnershipParticipantsPage from "./components/PartnershipParticipants/layout/PartnershipParticipants";
import UserConsentMOH from "./components/UserConsentMOH/layout/UserConsentMOH";
import CreateAccountPage from "./components/CreateAccountPage/layout/CreateAccountPage";

// Services
import IDPConnectService from "./components/Services/IDPConnectService"; 

// Utils
import i18n from './i18n';

// Helpers
import { Provider } from "./helpers/context";

// Css
import "./styles/App.css";

export default class App extends Component {
  state = {
    ready: false,
    isMenuOpen: false,
    isDrawerOpen: false
  }

  componentDidMount() {
    let that = this;
    let startup = [];
    // Get he learn more param from the url if the user coming from nav
    if (window.location && window.location.search) {
      const queryParts = qs.parse(window.location.search);

      IDPConnectService.setLearnMore(queryParts.learnMore);
    }

    // Modal Accessibility Warning Fix
    Modal.setAppElement('#root');

    startup.push(new Promise((resolve, reject) => {
      axios.get(`${process.env.PUBLIC_URL}/config/config.json`).then(function (res) {
        window.config = res.data;
        if (res.data.whitelabel) {
          var headID = document.getElementsByTagName('head')[0];
          var link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          headID.appendChild(link);
          link.href = res.data.whitelabel;
        }
        resolve();
      });
    }));

    startup.push(new Promise((resolve, reject) => {
      i18n.on('initialized', function() { 
        resolve();

        if (i18n.language.indexOf('fr')) {

          document.body.classList.add('lng-french');

        }
      });
    }));
   
    return Promise.all(startup).then(function() {
      // Analytics
      AnalyticsService.initialize(window.config.alias, {
        instrumentationKey: window.config.instrumentationKey,
        disableAjaxTracking: true,
      }).then(ready => {
        AnalyticsService.track("onLoad_event", {
          context: 'IAA login themes',
          page: window.PAGE === "qr_consent" ? "Sign in page" : "SP login page",
          url: window.location.hostname + window.location.pathname
        })
      })

      that.setState({ 
        ready: true,
      });
    })
  }

  handleViewBasedOnPage = () => {
    if (window.PAGE === "qr_consent") {
      return <PublicRoute component={SPloginPage} layout={WithoutSidebarLayout} />   
    } else {
      return <PublicRoute component={SignInPage} layout={WithoutSidebarLayout} />   
    }
  }

  handleToggleMenu = (e) => {
    // Prevent parent event
    e.stopPropagation();
    
    const { isMenuOpen } = this.state;

    this.setState({ isMenuOpen: !isMenuOpen })
  }

  handleCloseMenu = () => {
    this.setState({
      isMenuOpen: false,
      isDrawerOpen: false
    })
  }

  handleToggleDrawer = (e) => {
    // Prevent parent event
    e.stopPropagation();
    
    const { isDrawerOpen } = this.state;

    this.setState({ isDrawerOpen: !isDrawerOpen })
  }

  render() {
    const {
      ready,
      isMenuOpen,
      isDrawerOpen
    } = this.state;

    if (!ready) {
      return "";
    }

    const titleText = i18n.t("title");
    const noscriptText = i18n.t("noscript");

    return (
      <div className="App">
        <Helmet>
          <meta charset={i18n.t("charset")}/>
          <link href={i18n.t('favicon')} />
          <title>{titleText.toString()}</title>
          <noscript>{noscriptText.toString()}</noscript>
          <link rel="shortcut icon" href={window.config.favicon} />
        </Helmet>

        <Provider value={{ handleToggleMenu: this.handleToggleMenu, handleCloseMenu: this.handleCloseMenu, handleToggleDrawer: this.handleToggleDrawer, isMenuOpen, isDrawerOpen, learnMore: IDPConnectService.getLearnMore() }} >
          <div className="app-wrapper" tabIndex={0}>
            <Router>
              <main aria-labelledby="ontario-trusted-account">
                <Switch>  
                  <PublicRoute path={window.GLOBAL_PATH+"learn-more"} component={LearnMoreONTA} layout={WithoutSidebarLayout} />   
                  <PublicRoute path={window.GLOBAL_PATH+"signUpPartnerApp"} component={SignupPartnerAppPage} layout={WithoutSidebarLayout} />   
                  <PublicRoute path={window.GLOBAL_PATH+"privacy-policy"} component={PrivacyPolicy} layout={WithoutSidebarLayout} /> 
                  <PublicRoute path={window.GLOBAL_PATH+"terms-of-use"} component={TermsOfUse} layout={WithoutSidebarLayout} />   
                  <PublicRoute path={window.GLOBAL_PATH+"faq"} component={() => <Redirect to={window.GLOBAL_PATH+"support"} />} layout={WithoutSidebarLayout} /> 
                  <PublicRoute path={window.GLOBAL_PATH+"support"} component={Support} layout={WithoutSidebarLayout} />   
                  {/* <PublicRoute path={window.GLOBAL_PATH+"partners-and-participants"} component={PartnershipParticipantsPage} layout={WithoutSidebarLayout} />    */}
                  <PublicRoute path={window.GLOBAL_PATH+"user-consent-for-ministry-of-health"} component={UserConsentMOH} layout={WithoutSidebarLayout} />
                  <PublicRoute path={window.GLOBAL_PATH+"create-account"} component={CreateAccountPage} layout={WithoutSidebarLayout} />   
                  {this.handleViewBasedOnPage()}          
                </Switch>
              </main>
            </Router>
          </div>
        </Provider>
      </div>
    );
  }
}
