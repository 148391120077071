import React, { Component } from "react";

// Components
import Spinner from "./Spinner";

class ButtonWithSpinner extends Component {
  render() {
    const {
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal,
      buttonText,
      handleGetStartedAction,
      className,
      style,
    } = this.props;

    return (
      <React.Fragment>
        {
          isInternetDisconnected
          ?
          <button 
            className={className}
            onClick={handleToggleNetworkModal} 
            style={style}
          >
            { buttonText }
          </button>
          :
          isSendingReq
          ?
          <button style={style} className={`${className} spinner-btn`}> 
            <Spinner />
          </button>
          :
          <button 
            style={style}
            className={className}
            onClick={handleGetStartedAction} 
          >
            { buttonText }
          </button>
        }
      </React.Fragment>
    );
  }
}

export default ButtonWithSpinner;
