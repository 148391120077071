import React, { Component } from "react";

// Services
import LearnMoreONTAPresentation from "../presentation/LearnMoreONTAPresentation";

class LearnMoreONTAContainer extends Component {
  render() {
    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
    } = this.props;
    
    return (
      <LearnMoreONTAPresentation 
        handleToggleModal={this.handleToggleModal}
        handleToggleNetworkModal={handleToggleNetworkModal} 
        isInternetDisconnected={isInternetDisconnected }        
      />
    )
  }
}

export default LearnMoreONTAContainer;
