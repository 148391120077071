import React, { Component } from 'react';

//images
import ontarioLogo from "../assets/logo-ontario.svg";

//Services
import i18n from '../i18n';

//lib
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';

//components
import HeaderMenu from "./HeaderMenu";
import Sidebar from "./Sidebar";

class Header extends Component {
  render() {
    const {
      showMenu,
      isMenuOpen,
      isDrawerOpen,
      handleToggleMenu,
      handleToggleDrawer,
      learnMore
    } = this.props;
    
    return (
      <header className="header-container">
        <div className="title-wrapper">
          <Link 
            to={learnMore ? window.GLOBAL_PATH+"learn-more" : "/"} 
          >
            <img className="accounttitle-text"src={ontarioLogo} alt="" role="presentation"/>
          </Link>
          <div className="sub-title">{i18n.t("signin_web_Ontario_sub_title")}</div>
        </div>
        
        {
          showMenu
          &&
          <React.Fragment>
            <Hidden xsDown implementation="css" >
              <button aria-label="menubar" className="menu-icon textPrimaryColor" onClick={handleToggleMenu}>
                <MenuIcon />
              </button>
            </Hidden>

            <Hidden smUp implementation="css">
              <button aria-label="menubar" className="menu-icon textPrimaryColor" onClick={handleToggleDrawer}>
                <MenuIcon />
              </button>
            </Hidden>
          </React.Fragment>
        }

        {
          isMenuOpen
          &&
          <HeaderMenu />
        }

        {/* Mobile Drawer */}
        <FocusTrap>
          <Drawer
            variant="temporary"
            anchor='right'
            open={isDrawerOpen}
            onClose={handleToggleDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Sidebar handleToggleDrawer={handleToggleDrawer} />
          </Drawer>
        </FocusTrap>
      </header>
    )
  }
}

export default Header;