import React, { Component } from 'react';

// Lib
import Iframe from 'react-iframe';

//components
import Header from "../../Header";
import PartnersFooter from "../../PartnersFooter";

//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";


class LearnMoreONTAPresentation extends Component {
  render() {
    return (
      <React.Fragment>
        <Consumer>
          {(value) => (
            <div 
              className="webLogin signin learn-more-onta"
              onClick={value.handleCloseMenu} 
            >
              <div className="webLogin-container">
                <Header 
                  showMenu={true}
                  isMenuOpen={value.isMenuOpen} 
                  handleToggleMenu={value.handleToggleMenu}
                  isDrawerOpen={value.isDrawerOpen}
                  handleToggleDrawer={value.handleToggleDrawer}
                  learnMore={value.learnMore}
                />

                <div className="see-options-container">
                  <div className="see-options-wrapper">
                    <div className="on-text-wrapper">
                      <h2 className="ont-text-div">
                        {i18n.t("signin_web_Ontario_acct")}
                      </h2>
                      <hr className="Divider backgroundPrimaryColor" />
                      <div className="gateway_text">
                        {i18n.t("signin_web_gateway_txt")}
                      </div>

                      <div className="watch_video_text">
                        {i18n.t("signin_web_watch_video_txt")}
                      </div>
                    </div>

                    <div className="video-section">
                      <div className="video-container">
                        <div className="video-wrap">
                          <Iframe 
                            url={window.config.video_url}
                            title={window.config.video_title}
                            width="100%"
                            height="100%"
                            display="block"
                            allowfullscreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Partners Sections */}

                <PartnersFooter />
              </div>
            </div>
          )}
        </Consumer>
      </React.Fragment>
    )
  }
}

export default LearnMoreONTAPresentation;
