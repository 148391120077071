import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import TermsOfUseContainer from "../container/TermsOfUseContainer";

// Services
import i18n from '../../../i18n';

class TermsOfUse extends Component {

  render() {
    return (
      <div className="privacy-policy terms-of-use">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("terms_of_use__header_title")}`} />
        <TermsOfUseContainer />
      </div>
    );
  }
}

export default TermsOfUse;
