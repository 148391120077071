import React, { Component } from "react";

// Services
import i18n from "../../i18n";

// Lib
import parse from 'html-react-parser';

// Components
import ButtonWithSpinner from "../ButtonWithSpinner";

class LoginPageModalTemplate extends Component {
  state = {
    tabIndex: 0,
    checked: false,
  }

  handleCheckBoxChange = () => {
    const { checked } = this.state;

    this.setState({ checked: !checked })
  }

  renderModalContent = (modalContent) => {
    const { device } = this.props;
    const { tabIndex } = this.state;

    return modalContent.map((panel, i) => (
      <div 
        className={device === "android" ? "android-panel panel" : (device === "web") ? "web-panel panel" : "panel"} 
        key={i}
        role="tabpanel"
        hidden={tabIndex !== i}
      >
        {
          tabIndex === i
          &&
          panel.panelElements.map((item, i) => (
            <React.Fragment key={i}>
              {parse(item)}
            </React.Fragment>
          ))
        }
      </div>
    ))
  }

  handleChangeTabIndex = (tabIndex) => {   
    const { handleScrollToTop } = this.props;

    handleScrollToTop && handleScrollToTop();

    this.setState({ tabIndex });
  }

  renderPaginationDots = (modalContent) => {
    const { tabIndex } = this.state;

    let renderDots = [];
  
    for (let i = 1; i <= modalContent.length; i++) {
      renderDots.push(<div key={i} className={(tabIndex + 1) === i ? "current-dot dot backgroundPrimaryColor" : "dot"} />)
    }

    return renderDots;
  }

  handleRedirect = () => {
    const { mobileScheme, idp } = this.props;

    window.location.assign(`${mobileScheme}login?idp=${idp}`);
  }

  render() {
    const {
      modalContent,
      handleGetStartedAction,
      showPagination,
      handleToggleModal,
      device,
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal,
    } = this.props;

    const {
      tabIndex,
    } = this.state;

    return (
      <div className="modal-content">
        {
          this.renderModalContent(modalContent)
        }
        
        {/* IOS/WEB BUTTONS GROUP */}
        
        <div 
          className={(device === "android") ? "android-btn-group btn-group" : (device === "web") ? "web-btn-group btn-group" : "btn-group"}
        >

          {/* Next Button */}
          {
            (modalContent.length > 1 && modalContent[tabIndex + 1])
            &&
            <button 
              className={(device === "android") ? "textPrimaryColor" :"btn-fill backgroundPrimaryColor"}
              onClick={() => this.handleChangeTabIndex(tabIndex + 1)}
            >
              {i18n.t("sign_in_modal_flow__next_btn")}
            </button>
          }


          {/* Android close btn */}
          {
            ((device === "android") && (tabIndex === 0))
            &&
            <button 
              className="close-btn textPrimaryColor"
              onClick={() => handleToggleModal("")}
            >
              {i18n.t("sign_in_modal_flow__close_btn")}
            </button>
          }

          {/* Get Started btn */}
          {
            (modalContent.length === (tabIndex + 1))
            &&
            <React.Fragment>
              {
                device === "web"
                ?
                <ButtonWithSpinner
                  isSendingReq={isSendingReq}
                  isInternetDisconnected={isInternetDisconnected}
                  handleToggleNetworkModal={handleToggleNetworkModal}
                  buttonText={i18n.t("sign_in_modal_flow__get_started")}
                  handleGetStartedAction={handleGetStartedAction}
                  className="backgroundPrimaryColor button--spinner btn-fill"
                />
                :
                <button 
                  className={(device === "android") ? "button--spinner textPrimaryColor" :"backgroundPrimaryColor button--spinner btn-fill"}
                  onClick={this.handleRedirect}
                >
                  {i18n.t("sign_in_modal_flow__get_started")}
                </button>
              }
            </React.Fragment>
          }

          {/* Previous Btn */}
          {
            (modalContent.length > 1 && modalContent[tabIndex - 1])
            &&
            <button 
              className="previous-btn textPrimaryColor"
              onClick={() => this.handleChangeTabIndex(tabIndex - 1)}
            >
              {i18n.t("sign_in_modal_flow__previous_btn")}
            </button>
          }
        </div>

        {
          showPagination
          && 
          <div className="dots">
            {
              this.renderPaginationDots(modalContent)
            }
          </div>
        }
      </div>
    );
  }
}

export default LoginPageModalTemplate;
