import IdpApi from "./IdpAPI";
import { BrowserStorage, SessionStorage } from "./BrowserStorage";

// Servicess

let IDPConnectService = {};

/**

!!! This sets state in the AuthService, login session id and secret

@param idp The idp object form the IdpApi
@param after ... what to do on return?

@return Promise with url for reidriect
*/
IDPConnectService.LoginToIdp = function(idp, after, last_view) {
    return new Promise((resolve, reject) => {
        IdpApi.requestIdpAuth(idp).then(
            result => {
               
                let inIframe = () => {
                    try {
                        return window.self !== window.top;
                    } catch (e) {
                        return true;
                    }
                }

                if (!inIframe()) {
                    BrowserStorage.set("idp_after_type", after);
                    BrowserStorage.set("idp_id", idp);
                    BrowserStorage.set("idp_request_id", result.pending_auth_request_id);
                    BrowserStorage.set("idp_request_secret", result.session_key);
                    BrowserStorage.set("idp_last_view_name", last_view);      
                }
                else {
                    
                    window.parent.postMessage(JSON.stringify({
                        idp_after_type: after,
                        idp_id: idp,
                        idp_request_id: result.pending_auth_request_id,
                        idp_request_secret: result.session_key,
                        idp_last_view_name: last_view
                    }), "*");
                }

                resolve(result.auth_request + `&referrer=${window.THEME}-${window.PLATFORM}`);
            },
            error => reject(error)
        );
    });
};


IDPConnectService.setLearnMore = function (params) {
    return SessionStorage.set("learnMore", params);
};

IDPConnectService.getLearnMore = function () {
    return SessionStorage.get("learnMore");
};

export default IDPConnectService;
