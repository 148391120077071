import React, { Component } from 'react';

// Lib
import Modal from "react-modal";
import i18n from "../i18n";

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isInternetDisconnected: false,
      isOpenModal: false
    }

    componentDidMount = () => {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount = () => {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (prevState.isInternetDisconnected && !this.state.isInternetDisconnected) {
        this.handleReload();
      }
    }

    handleConnectionChange = () => {
      if (navigator.onLine) {
        return this.setState({
          isInternetDisconnected: false,
          isOpenModal: false
        });
      }

      return this.setState({ isInternetDisconnected: true, isOpenModal: true });
    }

    handleReload = () => {
      window.location.reload();
    }

    handleToggleNetworkModal = () => {
      const { isOpenModal } = this.state;
      this.setState({ isOpenModal: !isOpenModal })
    }
    render() {
      const { isOpenModal, isInternetDisconnected } = this.state;
      const platform = window.PLATFORM;

      return (
        <div>
          { 
            (isOpenModal && (platform === "web"))
            && 
            <Modal
              isOpen={isOpenModal}
              ariaHideApp={true}
              overlayClassName="modal-overlay"
            >
              <div className = "modal-wrapper network-editor custom-modal">
                <div className="header textPrimaryColor">
                  {
                    i18n.t("networkError__connection_failed")
                  }
                </div>
                <div className="modal-body">
                    {i18n.t("networkError__desc")} 
                </div>
                <div className="footer">
                  <button className="okBtn backgroundPrimaryColor" onClick={this.handleToggleNetworkModal}>
                    {i18n.t("ok")}
                  </button>
                </div>
              </div>
            </Modal>
          }
          <ComposedComponent {...this.props} handleReload={this.handleReload} isInternetDisconnected={isInternetDisconnected} handleToggleNetworkModal={this.handleToggleNetworkModal} />
        </div>
      );
    }
  }

  return NetworkDetector;
}