import React, { Component } from "react";

// Lib
import qs from "query-string";

// Components
import FaqContainer from "../container/FaqContainer";



class Faq extends Component {
  render() {
    const queryParts = qs.parse(window.location.search);

    return (
      <div className="privacy-policy faq">
        <FaqContainer incomingFaqID={queryParts.faqID} />
      </div>
    );
  }
}

export default Faq;
