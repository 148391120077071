import React from 'react'

import ontarioLogo from "../../../assets/logo-ontario.svg";
import nhLogo from "../../../assets/logo-nh-large.svg";

const LogosWrapper = () => {
  return (
    <div className="logosWrapper">
        <img src={nhLogo} className="center" alt="NH" />
        <img src={ontarioLogo} className="center" alt="Ontario" />
    </div>
  )
}

export default LogosWrapper