import React, { Component } from 'react'

//components
import Header from "../../Header";

// Lib
import AnalyticsService from "analytics-web";
import parse, { domToReact } from 'html-react-parser';

//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";

// Images
import IconPhone from "../../../assets/icon_phone.svg"

export default class SupportContainer extends Component {

  componentDidMount = () => this.handleAnalyticsTrack("onLoad_event");

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'IAA login themes',
      page: "FAQ page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  handleRenderContent = () => {
    let phone_number = i18n.t("support__phone_number", { returnObjects: true });

    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class) {
          if ((domNode.attribs.class.includes('tel') || domNode.attribs.class.includes('mail') || domNode.attribs.class.includes('link'))) {
            return <a className={domNode.attribs.class} href={domNode.attribs.href} rel={domNode.attribs.rel} target={domNode.attribs.target} onClick={() => this.handleAnalyticsTrack("onClick_event", domNode.attribs.click_details)}>{domToReact(domNode.children, options)}</a>;
          }
        }
      }
    };

    return (
      <div className="support-list">
        {parse(phone_number, options)}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Consumer>
          {(value) => (
            <div className="" onClick={value.handleCloseMenu}>
              <Header
                showMenu={true}
                isMenuOpen={value.isMenuOpen}
                handleToggleMenu={value.handleToggleMenu}
                isDrawerOpen={value.isDrawerOpen}
                handleToggleDrawer={value.handleToggleDrawer}
                learnMore={value.learnMore}
              />

              <div className="privacy-policy-container support-container">
                <div className="header">
                  <h2>{i18n.t("support__header_title")}</h2>
                  <hr className="backgroundPrimaryColor" />
                </div>

                <div className="content">
                  <section className="support">
                    <div className="support-inner-container">
                      <img alt="Phone Icon" src={IconPhone} />
                      <div>
                        {parse(i18n.t("support__header_desc"))}
                        {this.handleRenderContent()}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          )}
        </Consumer>
      </React.Fragment>
    );
  }
}
