import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import UserConsentMOHContainer from "../container/UserConsentMOHContainer";

// Services
import i18n from '../../../i18n';

class UserConsentMOH extends Component {
  render() {
    return (
      <div className="privacy-policy support">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("user_consent_MOH_page_title")}`} />
        <UserConsentMOHContainer />
      </div>
    );
  }
}

export default UserConsentMOH;
