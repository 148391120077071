import React, { Component } from 'react';

// Components
import BottomLinks from "../../BottomLinks";
import ButtonInterac from "../../ButtonInterac";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';
import LogosWrapper from '../../common/LogosWrapper'


//lib
import parse from 'html-react-parser';

// Images
import ontarioIcon from "../../../assets/logo-ontario.svg";
import bankIcon from "../../../assets/icon_bank.png";
import healthIcon from "../../../assets/icon_health.png";
import wifiIcon from "../../../assets/icon_wifi.png";

// Services
import i18n from '../../../i18n';

class CreateAccountWebMobContainer extends Component {
  state = {
    isOpen: false,
    modalContent: '',
  }

  handleToggleModal = () => this.setState({ isOpen: !this.state.isOpen })

  handleModalContent = (modalType) => {
    switch(modalType) {
      case 'bank':
        this.setState({modalContent: 'create_account_modal_bank'})
        break;
        case 'health':
          this.setState({modalContent: 'create_account_modal_health'})
        break;
      default:
    };

    this.handleToggleModal()
  };

  render() {
    const {
      device,
      mobileScheme,
      idp,
      isInternetDisconnected,
      handleGetStartedAction,
      handleToggleNetworkModal,
      isSendingReq,
      handleCreateAccountClick
    } = this.props;

    const { isOpen, modalContent } = this.state;

    const options = {
      replace: ({ attribs }) => {
        if (!attribs) {
          return;
        }
        if (attribs.class === 'bankLink') {
          return <span className='link' onClick={() => this.handleModalContent('bank')}>{parse(i18n.t("create_account_description_bank_link"))}</span>;
        }
        if (attribs.class === 'healthLink') {
          return <span className='link' onClick={() => this.handleModalContent('health')}>{i18n.t("create_account_description_health_link")}</span>;
        }
        if (attribs.class === 'wifiIcon') {
          return <img src={wifiIcon} alt='' role='presentation' />
        }
        if (attribs.class === 'bankIcon') {
          return <img src={bankIcon} alt='' role='presentation' />
        }
        if (attribs.class === 'healthIcon') {
          return <img src={healthIcon} alt='' role='presentation' />
        }
      }
    };

    return (
      <div className="signin mobile-signin create-account">
        <div className={(device === "android") ? "signin__SigninContainer android_margin" : (device === "ios") ? "signin__SigninContainer ios_margin" : "signin__SigninContainer def_margin"}>
          <header
            className={(device === "android") ? "imgContainer android_imgPadding" : (device === "ios") ?
              "imgContainer ios_imgPadding" : "imgContainer def_imgPadding"}>
            <img src={ontarioIcon} className="center" alt="" role="presentation" />

            <h1 className="titleText">{i18n.t("create_account_title")}</h1>
          </header>

          <section className="signinContentsWrapper">
            <div className="gatewayTextWrapper signInModal">
              <div className="gatewaySubText" >
                {parse(i18n.t("create_account_description_1", { name: device + "_text" }))}
                {parse(i18n.t("create_account_description_2", { name: device + "_text" }))}
                {parse(i18n.t("create_account_description_3", { name: device + "_text" }))}
                {parse(i18n.t("create_account_description"), options)}
              </div>

            </div>

            <ButtonInterac
              buttonClick={() => { isInternetDisconnected ? handleToggleNetworkModal() : (!isSendingReq && handleGetStartedAction()) }}
              device={device}
              mobileScheme={mobileScheme}
              idp={idp}
            />

            <div className="buttonsWrapper">
              <button className="textPrimaryColor learnMore" onClick={handleCreateAccountClick}>{i18n.t("cancel")}</button>
            </div>
          </section>
        </div>

        <footer className="footer-div">
          <div className="fundedDesc">{i18n.t("signIn_fundedBy_text")}</div>

          <LogosWrapper />

          <BottomLinks />
        </footer>

        {
          isOpen
          &&
          <Modal
            isOpen={isOpen}
            ariaHideApp={true}
            overlayClassName={(device === "web") ? "modal-overlay signInModal web-modal-overlay" : "modal-overlay signInModal"}
            contentLabel={i18n.t("create_account_title")}
          >
            <React.Fragment>
              <div id='modal-content'>
                <div className="modal-exit-wrapper">
                  <button aria-label="close" className="close-icon" onClick={() => this.handleToggleModal("")} >
                    <CloseIcon />
                  </button>
                </div>
                <div className="modal-content">
                  <div className='panel' role="tabpanel">
                    {parse(i18n.t(modalContent))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          </Modal>
        }
      </div>
    )
  }
}

export default CreateAccountWebMobContainer;
