import React, { Component } from 'react';

//Services
import i18n from '../i18n';
import parse from 'html-react-parser';

class ButtonInterac extends Component {
  render() {
    const { 
      buttonClick,
      device,
      mobileScheme,
      idp
    } = this.props;
    
    console.log('idp', idp)
    console.log('mobileScheme', mobileScheme)
    return (
      device === 'web' ? (
        <button className='button-interac' onClick={buttonClick}>
          {parse(i18n.t('interac_btn'))}
        </button>
      ) : (
        <a 
          href={`${mobileScheme}login?idp=${idp}`}
          className = {
            (device === "android") ? "button-interac button android_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg" : (device === "ios") ? "button-interac button ios_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg" : "button def_margin sign-in-btn borderPrimaryColor textPrimaryColor button--spinner-bg"
          }
        >
          {parse(i18n.t("interac_btn"))}
        </a>
      )
    )
  }
}

export default ButtonInterac;