import React, { Component } from "react";

// Lib
import AnalyticsService from "analytics-web";

// Components
import ErrorModal from "../../ErrorModal";

// Services
import i18n from '../../../i18n';
import IDPConnectService from "../../Services/IDPConnectService";
import CreateAccountWebMobContainer from "../presentation/CreateAccountWebMobContainer";

class CreateAccountContainer extends Component {  
  state = {
    device: this.props.xDevice,
    idpRedirectUrl: null,
    hasError: false,
    errorTitle: "",
    errorMessage: "",
    isLoginFailed: this.props.isLoginFailed,
    isSendingReq: false
  }

  componentDidMount = () => {
    const { isLoginFailed } = this.state;
    let errTitle = "";
    let errMessage = "";
    let hasError = false;

    if (isLoginFailed) {
      hasError = true
      errTitle = i18n.t("login__logging_error_title");
      errMessage = i18n.t("login__logging_error_desc");
    }

    this.setState({
      errorTitle: errTitle,
      errorMessage: errMessage,
      hasError,
    });
  }

  componentWillUnmount = () => {
    this.setState({
      isSendingReq: false
    })
  }

  handleScrollToTop = () => {
    setTimeout(() => {
      let scrollDiv = document.getElementsByClassName('ReactModal__Content--after-open')[0];
      scrollDiv.scrollTop = 0;
    }, 100);
  }

  handleGetStartedAction = () => {

    const { idp } = this.props;

    this.setState({ isSendingReq: true })
    return IDPConnectService.LoginToIdp(idp, "auth", "CONSENT_QR").then(
      redirectUrl => {

        //Analytics
        AnalyticsService.track("onClick_event", {
          context: 'IAA login themes',
          page: "Login page",
          actions: 'sign in click event',
          url: window.location.hostname + window.location.pathname
        })

        this.setState({
          idpRedirectUrl: redirectUrl
        })
      }).catch(e => {
        //Analytics
        AnalyticsService.track("error", {
          context: 'IAA login themes',
          page: "Login page",
          reason: e,
          url: window.location.hostname + window.location.pathname
        })

        this.setState({
          hasError: true,
          errorTitle: i18n.t("signin_failure_title"),
          errorMessage: i18n.t("signin_failure_desc"),
          isSendingReq: false
        })
      });
  }

  handleToggleErrorModal = () => {
    // Clearing browser state 
    window.history.replaceState("state", null);
    window.localStorage.clear();
    this.setState({ isLoginFailed: false, hasError: false })
  }

  render() {
    const {
      device,
      idpRedirectUrl,
      hasError,
      errorTitle,
      errorMessage,
      isLoginFailed,
      isSendingReq
    } = this.state;

    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
      idp,
      mobileScheme,
      handleCreateAccountClick
    } = this.props;

    if (idpRedirectUrl !== null) {
      window.top.location.href = idpRedirectUrl;
    }

    return (
      <React.Fragment>
          <CreateAccountWebMobContainer 
            handleGetStartedAction={this.handleGetStartedAction}
            handleToggleNetworkModal={handleToggleNetworkModal} 
            isInternetDisconnected={isInternetDisconnected }
            isSendingReq={isSendingReq}
            device={device}    
            idp={idp}
            mobileScheme={mobileScheme}
            handleCreateAccountClick={handleCreateAccountClick}
          />
        
        {
          (hasError || isLoginFailed)
          &&
          <ErrorModal
            isOpen={hasError || isLoginFailed}
            errorTitle={errorTitle}
            errorMessage={errorMessage}
            handleToggleModal={this.handleToggleErrorModal}
          />
        } 
      </React.Fragment>
    )
  }
}

export default CreateAccountContainer;
