import React, { Component } from 'react';

// Lib
import Iframe from 'react-iframe';

//components
import ButtonWithSpinner from "../../ButtonWithSpinner";
import Header from "../../Header";
import PartnersFooter from "../../PartnersFooter";

//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";

class WebSignInComponent extends Component {
  render() {
    const {
      handleGetStartedAction,
      handleToggleNetworkModal,
      isInternetDisconnected,
      isSendingReq,
      handleCreateAccountClick
    } = this.props;
    
    return (
      <React.Fragment>
        <Consumer>
          {(value) => (
            <div 
              className="webLogin signin"
              onClick={value.handleCloseMenu} 
            >
              <div className="webLogin-container">
                <Header 
                  showMenu={true}
                  isMenuOpen={value.isMenuOpen} 
                  handleToggleMenu={value.handleToggleMenu}
                  isDrawerOpen={value.isDrawerOpen}
                  handleToggleDrawer={value.handleToggleDrawer}
                />

                <section className="see-options-container">
                  <div className="see-options-wrapper">
                    <div className="on-text-wrapper">
                      <h2 className="ont-text-div">
                        {i18n.t("signin_web_Ontario_acct")}
                      </h2>
                      <hr className="Divider backgroundPrimaryColor" />
                      <div className="gateway_text">
                        {i18n.t("signin_web_gateway_txt")}
                      </div>

                      <div className="watch_video_text">
                        {i18n.t("signin_web_watch_video_txt")}
                      </div>
                    </div>

                    <div className="video-section">
                      <div className="video-container">
                        <div className="video-wrap">
                          <Iframe 
                            url={window.config.video_url}
                            title={window.config.video_title}
                            width="100%"
                            height="100%"
                            display="block"
                            allowfullscreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Set Up Account Sections */}

                <section className="setup_account">
                  <div className="setup_account_wrapper">         
                    <div className="left_section">
                      <h3 className="setup_heading">
                        {i18n.t("signin_web_trusted_title")}
                      </h3>
                      <hr className="Divider backgroundPrimaryColor" />
                      <span className="setup_access_text">
                        {i18n.t("signin_web_trusted_desc")}
                      </span>
                    </div>

                    <article className="set_account_mask">
                      <div className="set_account_mask_container">

                        <h3>{i18n.t("signin_web_setUp_txt")} </h3>
                        <a 
                          href={window.GLOBAL_PATH+"signUpPartnerApp"}
                          className="see_options-button backgroundPrimaryColor"
                          aria-label={i18n.t("signin_web_seeoptions_btn")}
                          target="_top"
                        >
                          {i18n.t("signin_web_seeoptions_btn")}
                        </a>
                        <div className="or_divider">
                          <div className="line" />
                          <span>{i18n.t("signin_web_or")}</span>
                        </div>

                        <div className="buttonsWrapper">

                          <ButtonWithSpinner
                            isSendingReq={isSendingReq}
                            isInternetDisconnected={isInternetDisconnected}
                            handleToggleNetworkModal={handleToggleNetworkModal}
                            buttonText={i18n.t("signin_web_trusted_signin_btn")}
                            handleGetStartedAction={handleGetStartedAction} 
                            className={"button sign-in-btn textPrimaryColor button--spinner-bg"}
                          />
                          <button
                            className="setup_button textPrimaryColor"
                            aria-label={i18n.t("signin_web_trusted_setUp_btn")}
                            onClick={handleCreateAccountClick}
                          >
                            {i18n.t("signin_web_trusted_setUp_btn")}
                          </button>
                        </div>
                      </div>
                    </article>

                  </div>
                </section>

                {/* Partners Sections */}

                <PartnersFooter />
              </div>
            </div>
          )}
        </Consumer>
      </React.Fragment>
    )
  }
}

export default WebSignInComponent;
