import React, { Component } from 'react';

// Lib
import Helmet from "react-helmet";
import SPLoginContainer from '../container/SPLoginContainer';
import CreateAccountContainer from '../../CreateAccountPage/container/CreateAccountContainer'

// Services
import i18n from '../../../i18n';

class SPloginPage extends Component {
  state = {
    isCreateAccount: false
  }

  handleCreateAccountClick = () => {
    this.setState({isCreateAccount: !this.state.isCreateAccount})
    window.scrollTo(0, 0);
  }

  render() {
    const { isInternetDisconnected, handleToggleNetworkModal, history } = this.props;
    const { isCreateAccount } = this.state;
    const idp = window.IDP_OPTIONS && window.IDP_OPTIONS.length && window.IDP_OPTIONS[0].idpAuthenticatorId;
    const user_code = window.USER_CODE;
    const verification_uri_complete = window.VERIFICATION_URI_COMPLETE;
    const sp_name = window.SP_NAME;
    const platform = window.PLATFORM;
    const mobileScheme = window.MOBILE_SCHEME;

    return (
      <div className="sp-login-page">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("sign_in_page_title")}`} />
        {
          !isCreateAccount ? (
            <SPLoginContainer
              isInternetDisconnected={isInternetDisconnected}
              handleToggleNetworkModal={handleToggleNetworkModal}
              isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed}
              idp={idp}
              user_code={user_code}
              verification_uri_complete={verification_uri_complete}
              sp_name={sp_name}
              xDevice={platform}
              handleCreateAccountClick={this.handleCreateAccountClick}
            />
          ) : (
            <CreateAccountContainer 
              isInternetDisconnected={isInternetDisconnected}   
              handleToggleNetworkModal={handleToggleNetworkModal}
              isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed}
              xDevice={platform}
              idp={idp}
              mobileScheme={mobileScheme}
              history={history}
              handleCreateAccountClick={this.handleCreateAccountClick}
            />
          )
        }
      </div>
    )
  }
}

export default SPloginPage
