import React, { Component } from 'react'

//components
import Header from "../../Header";

// Lib
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AnalyticsService from "analytics-web";
import parse, { domToReact } from 'html-react-parser';

//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";

export default class PrivacyPolicyContainer extends Component {

  constructor(props) {
  super(props);

    const sidebarNavItems = i18n.t("privacy_policy__content", {returnObjects: true});

    let selectedTitleIDs = [];
    
    sidebarNavItems.map(item => {
      selectedTitleIDs.push(item.title_id)

      return item;
    })

    this.state = {
      selectedNavId: "what_is_the_pdiaa_service",
      selectedTitleIDs,
      sidebarNavItems
    }

  }

  componentDidMount = () => {
    //Analytics
    AnalyticsService.track("onLoad_event", {
      context: 'IAA login themes',
      page: "Privacy policy page",
      url: window.location.hostname + window.location.pathname
    })
  }

  handleRenderContent = () => {
    const {
      sidebarNavItems,
      selectedNavId
    } = this.state;
  
    return <React.Fragment>
      <div className="aside">
        <h3 className="title">{i18n.t("privacy_policy__aside_title")}</h3>
        {
          sidebarNavItems.map(item => (
            <div className="container" key={item.title_id}>
                <button 
                  onClick={() => this.handleSidebarNavClick(item.title_id)}
                  className={selectedNavId === item.title_id ? "selected textPrimaryColor" : "textPrimaryColor"}
                >
                  {item.title}
                </button>
            </div>
          ))
        }
      </div>

      {
        this.handleShowSectionContent()
      }
    </React.Fragment>
  }

  handleShowSectionContent = () => {
    const {
      sidebarNavItems,
      selectedTitleIDs,
    } = this.state;

    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class) {
          const classArr = (domNode.attribs.class).split(' ')
          const includedLinks = ['support', 'terms-of-use'];
          const linkPath = (classArr.filter(element => includedLinks.includes(element)))[0];
          if (linkPath) return <a className={domNode.attribs.class} target={domNode.attribs.target} rel={domNode.attribs.rel} href={window.GLOBAL_PATH+linkPath}>{domToReact(domNode.children, options)}</a>;
        }
      }
    }

    return (
      <section>
        {
          sidebarNavItems.map(item => (
            <div className="content-item" key={item.title_id} id={item.title_id}>
              <button 
                onClick={() => this.handleContentTitleClick(item.title_id)}
              >
                {
                  item.custom_title ?
                  <>
                    {
                      parse(item.custom_title)
                    } 
                  </>
                  :
                  <span className={'title'}>{item.title}</span>
                }
                {
                  (selectedTitleIDs.includes(item.title_id))
                  ?
                  <ArrowDropUpIcon className="caretStyle textPrimaryColor" />
                  :
                  <ArrowDropDownIcon className="caretStyle textPrimaryColor" />
                }
              </button>

              {
                (selectedTitleIDs.includes(item.title_id))
                &&
                <div className="title-description" >
                  {
                    parse(item.description, options)
                  }
                </div>
              }
            </div>   
          ))
        }
      </section>
    )
  }

  handleSidebarNavClick = (selectedNavId) => {
    const { selectedTitleIDs } = this.state;

     //Scroll to specific section if url has faqid
    document.getElementById(selectedNavId).scrollIntoView({
      behavior: 'smooth'
    });
    
    this.setState({ selectedNavId, selectedTitleIDs: [...selectedTitleIDs, selectedNavId] }, () => {
      this.handleShowSectionContent();
    });
  }

  handleContentTitleClick = (selectedID) => {
    const { selectedTitleIDs } = this.state;

    let tempSelectedTitleIDs = selectedTitleIDs;
    
    if (tempSelectedTitleIDs.includes(selectedID)) {
      tempSelectedTitleIDs = tempSelectedTitleIDs.filter(item => item !== selectedID);
    } else {
      tempSelectedTitleIDs = [...tempSelectedTitleIDs, selectedID]
    }

    this.setState({
      selectedTitleIDs: tempSelectedTitleIDs,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Consumer>
            {(value) => (
              <div 
                className="signup-partners-app-container"
                onClick={value.handleCloseMenu} 
              >
                <Header 
                  showMenu={true}
                  isMenuOpen={value.isMenuOpen} 
                  handleToggleMenu={value.handleToggleMenu}
                  isDrawerOpen={value.isDrawerOpen}
                  handleToggleDrawer={value.handleToggleDrawer}
                  learnMore={value.learnMore}
                />

                <div className="privacy-policy-container">
                  <div className="header">
                    <h2>{i18n.t("privacy_policy__header_title")}</h2>
                    <hr className="backgroundPrimaryColor" />
                    {parse(i18n.t("privacy_policy__header_desc"))}
                  </div>

                  <div className="content">
                    {
                      this.handleRenderContent()
                    }
                  </div>
                </div>

                <div className="footer-div">
                  <div className="funded-by_txt">{i18n.t("signin_web_funded_by_txt")}</div>
                </div>
              </div>
          )}
        </Consumer>
      </React.Fragment>
    )
  }
}
