import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import LearnMoreONTAContainer from "../container/LearnMoreONTAContainer";

// Services
import i18n from '../../../i18n';

class LearnMoreONTA extends Component {

  render() {
    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;
    
    return (
      <div className="sign-in-page">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("learn_more_onta_page_title")}`} />
        <LearnMoreONTAContainer 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
        />
      </div>
    );
  }
}

export default LearnMoreONTA;
