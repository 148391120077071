import React from 'react';

//images
import nhLogoLarge from "../assets/logo-nh-large.svg";
import southLakeLogo from "../assets/logo-southlake.svg";
import nyghLogo from "../assets/logo-nygh.svg";
import stJosephsLogo from "../assets/logo-st-joseph.svg";

//Services
import i18n from '../i18n';

const PartnersFooter = () => (
  <section className="partners">
    <div className="partners_wrapper">
      <p className="partners_title">
        {i18n.t("signin_web_partners")}
      </p>
      <p className="partners_text">
        {i18n.t("signin_web_partners_logos")}

      </p>
      <div className="logosWrapper">
        <img src={nhLogoLarge} className="center" alt="Niagara health" />
        <img src={southLakeLogo} className="center" alt="SouthLake Regional Health Center" />
        <img src={nyghLogo} className="center" alt="North York General" />
        <img src={stJosephsLogo} className="center" alt="St Joseph's Healthcare Hamilton" />
      </div>
      <div className="funded-by_txt ">{i18n.t("signin_web_funded_by_txt")}</div>
    </div>
  </section>
)

export default PartnersFooter;