import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import SignupPartnerAppContainer from "../container/SignupPartnerAppContainer";

// Services
import i18n from '../../../i18n';

class SignupPartnerAppPage extends Component {

  render() {
    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;
    
    return (
      <div className="signup-partners-app">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("signupPartnerApp_page_title")}`} />
        <SignupPartnerAppContainer 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
        />
      </div>
    );
  }
}

export default SignupPartnerAppPage;
