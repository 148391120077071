import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import CreateAccountContainer from "../container/CreateAccountContainer";

// Services
import i18n from '../../../i18n';

class SignInPage extends Component {
  render() {
    const { isInternetDisconnected, handleToggleNetworkModal, history } = this.props;
    const platform = window.PLATFORM;
    const mobileScheme = window.MOBILE_SCHEME;
    const idp = window.IDP_OPTIONS && window.IDP_OPTIONS.length && window.IDP_OPTIONS[0].idpAuthenticatorId;
    return (
      <div className="sign-in-page">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("sign_in_page_title")}`} />
        <CreateAccountContainer 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
          isLoginFailed={this.props.location.state && this.props.location.state.isLoginFailed}
          xDevice={platform}
          idp={idp}
          mobileScheme={mobileScheme}
          history={history}
        />
      </div>
    );
  }
}

export default SignInPage;
