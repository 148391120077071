import axios from "axios";

let IdpAPI = {};

IdpAPI.requestIdpAuth = function(idpId) {
  return new Promise((resolve, reject) => {
    axios
      .post(window.config.url + "/login/idps/" + idpId + "/requests", {})
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default IdpAPI;
