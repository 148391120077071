import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";

// Components
import SupportContainer from "../container/SupportContainer";
import Faq from "../../Faq/layout/Faq"

// Services
import i18n from '../../../i18n';

class Support extends Component {
  render() {
    return (
      <>
      <div className="privacy-policy support">
        <Helmet title={`${i18n.t("signin_web_Ontario_title")} - ${i18n.t("support_page_title")}`} />
        <SupportContainer />
      </div>
      <Faq></Faq>
      </>
    );
  }
}

export default Support;
