import React, { Component } from 'react';

// Lib
import { Link } from "react-router-dom";
import AnalyticsService from "analytics-web";

//images
import nhIcon from "../../../assets/app-icon-nh.svg";
import nyIcon from "../../../assets/app-icon-ny.svg";
import sjIcon from "../../../assets/app-icon-sj.svg";
import slIcon from "../../../assets/app-icon-sl.svg";

//components
import Header from "../../Header";
import BottomLinks from "../../BottomLinks";

//Services
import i18n from '../../../i18n';

// Helpers
import { Consumer } from "../../../helpers/context";

export default class SignupPartnerAppContainer extends Component {
  componentDidMount = () => {
    this.handleAnalytics("onLoad_event");
  }

  handleAnalytics = (type, action) => {
    //Analytics
    AnalyticsService.track(type, {
      context: 'IAA login themes',
      page: "Sign up partner app page",
      action: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  render() {
    return (
      <React.Fragment>
        <Consumer>
            {(value) => (
              <div 
                className="signup-partners-app-container"
                onClick={value.handleCloseMenu} 
              >
                <Header 
                  isMenuOpen={value.isMenuOpen} 
                  handleToggleMenu={value.handleToggleMenu}
                  isDrawerOpen={value.isDrawerOpen}
                  handleToggleDrawer={value.handleToggleDrawer}
                />

                <div className="signup-contents-full">
                  <div className="signup-contents-full-wrapper">
                    <div className="signup-contents-first">
                      <div className="signup-contents-text-wrapper">
                        <div className="signup-contents-wrapper">
                          <h2 className="signup-title">
                            {i18n.t("signUpPartner_title")}
                          </h2>
                          <hr className="signup-divider backgroundPrimaryColor" />
                          <p className="signup-convinience-text">
                            {i18n.t("signUpPartner_convinience_txt")}</p>
                        </div>
                      </div>
                    </div>

                    <div className="signup-contents-mask-wrapper">
                      <div className="signup-contents-mask">

                        <h3 className="signup-set-mask-title">
                          {i18n.t("signUpPartner_mask_title")}
                        </h3>
                        <p className="signup-set-mask-desc">{i18n.t("signUpPartner_mask_desc")}</p>
                        <div className="partner-apps-wrapper">
                          <div className="nh_wrapper">
                            <img src={nhIcon} className="nh_img" alt="Niagara Health" />
                            {/* Hidden */}
                            <span className='sr-only' id="new-window">{i18n.t("new_tab_msg_for_sr")}</span>
                            
                            <div className="health-nav">
                              <span>{i18n.t("signUpPartner_niag_txt")}</span>
                              <a aria-label={`${i18n.t("signUpPartner_niag_txt")} ${i18n.t("signUpPartner_app_store")}`} className="use-this-link textPrimaryColor" href={window.config.nh_app_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked Niagara health navigator app store link")} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">
                                {i18n.t("signUpPartner_app_store")}
                              </a>
                              <a aria-label={`${i18n.t("signUpPartner_niag_txt")} ${i18n.t("signUpPartner_play_store")}`} className="use-this-link textPrimaryColor" href={window.config.nh_play_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked Niagara health navigator play store link")} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">
                                {i18n.t("signUpPartner_play_store")}
                              </a>
                            </div>
                          </div>

                          <div className="nh_wrapper">
                            <img src={nyIcon} className="ny_img" alt="North York" />
                            <div className="health-nav">
                              <span>{i18n.t("signUpPartner_ny_txt")}</span>
                              <a aria-label={`${i18n.t("signUpPartner_ny_txt")} ${i18n.t("signUpPartner_app_store")}`} className="use-this-link textPrimaryColor" href={window.config.ny_app_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked North York navigator app store link")} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">
                                {i18n.t("signUpPartner_app_store")}
                              </a>
                              <a aria-label={`${i18n.t("signUpPartner_ny_txt")} ${i18n.t("signUpPartner_play_store")}`} className="use-this-link textPrimaryColor" href={window.config.ny_play_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked North York navigator play store link")} target="_blank" rel="noopener noreferrer">
                                {i18n.t("signUpPartner_play_store")}
                              </a>
                            </div>
                          </div>

                          <div className="nh_wrapper">
                            <img src={slIcon} className="sl_img" alt="South Lake" />
                            <div className="health-nav">
                              <span>{i18n.t("signUpPartner_sl_txt")}</span>
                              <a aria-label={`${i18n.t("signUpPartner_sl_txt")} ${i18n.t("signUpPartner_app_store")}`} className="use-this-link textPrimaryColor" href={window.config.sl_app_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked South lake navigator app store link")} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">
                                {i18n.t("signUpPartner_app_store")}
                              </a>
                              <a aria-label={`${i18n.t("signUpPartner_sl_txt")} ${i18n.t("signUpPartner_play_store")}`} className="use-this-link textPrimaryColor" href={window.config.sl_play_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked South lake navigator play store link")} target="_blank" rel="noopener noreferrer">
                                {i18n.t("signUpPartner_play_store")}
                              </a>
                            </div>
                          </div>

                          <div className="nh_wrapper">
                            <img src={sjIcon} className="sj_img" alt="St. Joseph" />
                            <div className="health-nav">
                              <span>{i18n.t("signUpPartner_sj_txt")}</span>
                              <a aria-label={`${i18n.t("signUpPartner_sj_txt")} ${i18n.t("signUpPartner_app_store")}`} className="use-this-link textPrimaryColor" href={window.config.sj_app_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked South lake navigator app store link")} target="_blank" rel="noopener noreferrer" aria-describedby="new-window">
                                {i18n.t("signUpPartner_app_store")}
                              </a>
                              <a aria-label={`${i18n.t("signUpPartner_sj_txt")} ${i18n.t("signUpPartner_play_store")}`} className="use-this-link textPrimaryColor" href={window.config.sj_play_store_url} onClick={() => this.handleAnalytics("onClick_event", "Clicked South lake navigator play store link")} target="_blank" rel="noopener noreferrer">
                                {i18n.t("signUpPartner_play_store")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="what-will-app-do">
                  <div className="what-will-app-do-wrapper">
                    <h3 className="what-will-app-do-title">
                      {i18n.t("signUpPartner_what_will_app_do")}
                    </h3>
                    <p className="what-will-app-do-text">
                      {i18n.t("signUpPartner_what_will_app_do_txt1")}
                    </p>
                    <p className="what-will-app-do-text">
                      {i18n.t("signUpPartner_what_will_app_do_txt2")} </p>
                      <Link className="faq-link textPrimaryColor"
                      aria-label={i18n.t("signUpPartner_FAQ")}
                      to={window.GLOBAL_PATH+"faq"}>
                        {i18n.t("signUpPartner_FAQ")}
                      </Link>
                  </div>

                  <div className="hidden" />
                </div>

                <div className="footer-div">
                  <div className="funded-by_txt">{i18n.t("signin_web_funded_by_txt")}</div>

                  <BottomLinks />
                </div>
              </div>
          )}
        </Consumer>
      </React.Fragment>
    )
  }
}
