import React, { Component } from "react";

// Lib
import AnalyticsService from "analytics-web";

// Components
import SwipeableModal from "../../SwipeableModal";
import ErrorModal from "../../ErrorModal";

// Images
import logoVerifiedMe from "../../../assets/logo-verified-me.svg";
import checkIcon from "../../../assets/icon-check.png";
import ontarioIcon from "../../../assets/icon-ontario.svg";

// Services
import i18n from '../../../i18n';
import IDPConnectService from "../../Services/IDPConnectService";
import WebSignInContainer from "../presentation/WebSignInContainer";
import MobSignInContainer from "../presentation/MobSignInContainer";

class SignInContainer extends Component {  
  state = {
    isModalOpen: false,
    device: this.props.xDevice,
    idpRedirectUrl: null,
    hasError: false,
    errorTitle: "",
    errorMessage: "",
    isLoginFailed: this.props.isLoginFailed,
    isSendingReq: false
  }

  componentDidMount = () => {
    const { isLoginFailed } = this.state;
    let errTitle = "";
    let errMessage = "";
    let hasError = false;

    if (isLoginFailed) {
      hasError = true
      errTitle = i18n.t("login__logging_error_title");
      errMessage = i18n.t("login__logging_error_desc");
    }

    this.setState({
      errorTitle: errTitle,
      errorMessage: errMessage,
      hasError,
    });
  }

  componentWillUnmount = () => {
    this.setState({
      isSendingReq: false
    })
  }

  handleToggleModal = () => {
    const { isModalOpen } = this.state;

    // Scroll to top on modal load  
    if (!isModalOpen) {
      this.handleScrollToTop();
    }

    this.setState({ isModalOpen: !isModalOpen });
  }

  handleSignInFlow = () => {
    window.location.href = window.GLOBAL_PATH+"create-account"
  }

  handleScrollToTop = () => {
    setTimeout(() => {
      let scrollDiv = document.getElementsByClassName('ReactModal__Content--after-open')[0];
      scrollDiv.scrollTop = 0;
    }, 100);
  }

  handleGetStartedAction = () => {

    const { idp } = this.props;

    this.setState({ isSendingReq: true })
    return IDPConnectService.LoginToIdp(idp, "auth").then(
      redirectUrl => {

        //Analytics
        AnalyticsService.track("onClick_event", {
          context: 'IAA login themes',
          page: "Login page",
          actions: 'sign in click event',
          url: window.location.hostname + window.location.pathname
        })

        this.setState({
          idpRedirectUrl: redirectUrl
        })
      }).catch(e => {
        //Analytics
        AnalyticsService.track("error", {
          context: 'IAA login themes',
          page: "Login page",
          reason: e,
          url: window.location.hostname + window.location.pathname
        })

        this.setState({
          hasError: true,
          isModalOpen: false,
          errorTitle: i18n.t("signin_failure_title"),
          errorMessage: i18n.t("signin_failure_desc"),
          isSendingReq: false
        })
      });
  }

  handleToggleErrorModal = () => {
    // Clearing browser state 
    window.history.replaceState("state", null);
    window.localStorage.clear();
    this.setState({ isLoginFailed: false, hasError: false })
  }

  render() {
    const {
      isModalOpen,
      device,
      idpRedirectUrl,
      hasError,
      errorTitle,
      errorMessage,
      isLoginFailed,
      isSendingReq,
    } = this.state;

    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
      idp,
      mobileScheme,
      handleCreateAccountClick
    } = this.props;

    if (idpRedirectUrl !== null) {
      window.top.location.href = idpRedirectUrl;
    }

    const modalContent = [{
      panelElements: [
        i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
        i18n.t("sign_in_modal_flow__panel_1_title"),
        i18n.t("sign_in_modal_flow__panel_1_desc")
      ]
    }, {
      panelElements: [
        i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
        i18n.t("sign_in_modal_flow__panel_2_title"),
        i18n.t("sign_in_modal_flow__panel_2_desc", { logoImage: logoVerifiedMe, listIcon: checkIcon }),
        i18n.t("sign_in_modal_flow__panel_2_learn_more_link", { link: `${window.GLOBAL_PATH}faq?faqID=i_have_questions_about_confirming_my_identity` })
      ]
    }, {
      panelElements: [
        i18n.t("sign_in_modal_flow__panel_header_img", { logoImage: ontarioIcon }),
        i18n.t("sign_in_modal_flow__panel_3_title"),
        i18n.t("sign_in_modal_flow__panel_3_desc", { listIcon: checkIcon }),
      ],
    }];

    return (
      <React.Fragment>
        {
          (device === "android" || device === "ios")
          ? 
          <MobSignInContainer 
            handleGetStartedAction={this.handleGetStartedAction}
            handleToggleNetworkModal={handleToggleNetworkModal}
            handleSignInFlow={this.handleSignInFlow}
            isInternetDisconnected={isInternetDisconnected }
            isSendingReq={isSendingReq}
            device={device}    
            idp={idp}
            mobileScheme={mobileScheme}
            handleCreateAccountClick={handleCreateAccountClick}
          />
          :
          <WebSignInContainer 
            handleGetStartedAction={this.handleGetStartedAction}
            handleToggleNetworkModal={handleToggleNetworkModal} 
            handleSignInFlow={this.handleSignInFlow}
            isInternetDisconnected={isInternetDisconnected }
            isSendingReq={isSendingReq}
            device={device}
            handleCreateAccountClick={handleCreateAccountClick}
          />
        }
        
        {
          isModalOpen
          &&
          <SwipeableModal
            isOpen={isModalOpen}
            showCloseIcon={true}
            showPagination={false}
            handleToggleModal={this.handleToggleModal}
            modalContent={modalContent}
            handleGetStartedAction={this.handleGetStartedAction}
            device={device}
            isSendingReq={isSendingReq}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            idp={idp}
            mobileScheme={mobileScheme}
            handleScrollToTop={this.handleScrollToTop}
          />
        }

        {
          (hasError || isLoginFailed)
          &&
          <ErrorModal
            isOpen={hasError || isLoginFailed}
            errorTitle={errorTitle}
            errorMessage={errorMessage}
            handleToggleModal={this.handleToggleErrorModal}
          />
        } 
      </React.Fragment>
    )
  }
}

export default SignInContainer;
