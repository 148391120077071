import React from "react";

//Lib
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import CloseIcon from '@material-ui/icons/Close';

class Sidebar extends React.Component {

    render() {
        const { handleToggleDrawer } = this.props;

        return (           
            <nav className="sidebar" aria-label="sidebar">
                <button onClick={handleToggleDrawer} className="close-icon-container">
                    <CloseIcon className="close-icon" />
                </button>
                <ul>
                    <li><Link className="textPrimaryColor" to={window.GLOBAL_PATH+"support"} target="_top">{i18n.t("headerMenu_support")}</Link></li>
                    <li><Link className="textPrimaryColor" to={window.GLOBAL_PATH+"terms-of-use"} target="_top">{i18n.t("headerMenu_terms_of_use")}</Link></li>
                    <li><Link className="textPrimaryColor" to={window.GLOBAL_PATH+"privacy-policy"} target="_top">{i18n.t("headerMenu_privacy_policy")}</Link></li>
                    <li><Link className="textPrimaryColor" to={window.GLOBAL_PATH+"user-consent-for-ministry-of-health"} target="_top">{i18n.t("headerMenu_user_consent_MOH")}</Link></li>
                </ul>
            </nav>
        );
    }
}

export default Sidebar;
